<template>
  <div class="home-con">
    <van-form @submit="onSubmit" style="margin-top:10px">
      <van-field name="platform" label="平台">
        <template #input>
          <van-radio-group v-model="config.platform" direction="horizontal">
            <van-radio name="win32">Win32</van-radio>
            <van-radio name="darwin">Darwin</van-radio>
            <van-radio name="android">Android</van-radio>
            <van-radio name="ios">Ios</van-radio>
            <van-radio name="all">All</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="get_num" label="数量">
        <template #input>
          <van-stepper v-model="config.create_num" />
        </template>
      </van-field>
      <van-field name="dev_num" label="设备数量">
        <template #input>
          <van-stepper v-model="config.dev_num" />
        </template>
      </van-field>
      <van-field name="time_limit" label="时限">
        <template #input>
          <van-radio-group v-model="config.time_limit">
            <van-radio :name="0">永久激活</van-radio>
            <van-radio name="7">7天</van-radio>
            <van-radio name="30">一月</van-radio>
            <van-radio name="120">季度</van-radio>
            <van-radio name="365">年</van-radio>
            <van-radio name="1">一天</van-radio>
            <van-radio name="0.5">12H</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">获取</van-button>
      </div>
      <div style="margin: 16px;">
        <van-button round block type="primary" @click.stop.prevent="createCode">创建</van-button>
      </div>
    </van-form>

    <van-popup v-model="codes_show" position="bottom" closeable close-icon="close" :style="{ height: '70%' }">
      <van-row style="margin-top:15px;padding:0 10px" type="flex">
        <!-- <van-col span="8">span: 8</van-col> -->
        <!-- <van-col span="8">span: 8</van-col> -->
        <van-col span="8" style="text-align:left;display:flex">
          <!-- <input id="input_all" type="text" readonly="true" class="copy-input"> -->
          <textarea id="input_all" rows="1" readonly="true" class="copy-input"></textarea>
          <van-button round type="primary" size="small" plain @click="copyCodeAll()">复制全部</van-button>
        </van-col>
      </van-row>
      <van-empty style="margin-top:50px" v-if="codes_list.length == 0" description="没找到" />
      <van-cell-group style="margin-top:10px">
        <template v-for="(item,index) in codes_list">
          <van-cell :key="index" center>
            <template #default>
              <van-row>
                <van-tag style="margin-right:5px" plain type="primary">{{item.is_used==0 || item.is_used==null?'未使用':'使用'}}</van-tag>
                <van-tag style="margin-right:5px" plain type="warning">{{item.platform}}</van-tag>
                <van-tag plain type="success">{{item.time_limit}}</van-tag>
              </van-row>
              <van-row>
                {{item.code}}
              </van-row>
            </template>
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <input :id="'input_'+index" type="text" readonly="true" class="copy-input">
              <van-button type="primary" @click="copyCode(index,item.code)" round size="normal">复制</van-button>
            </template>
          </van-cell>
        </template>
      </van-cell-group>
    
    </van-popup>
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
const execCopy = (input_id,value)=>{
    const input = document.getElementById(input_id);
    input.value = value;
    // 聚焦
    input.focus();
    // 选择需要复制的文本
    if (input.setSelectionRange) {
        input.setSelectionRange(0, input.value.length);
    } else {
        input.select();
    }
    try {
        const result = document.execCommand('copy');
        if(result){
          Toast.success('复制激活码成功')
        }
    } catch (e) {
      Toast.fail('复制失败，请重试~')
    }
}
export default {
  name: 'Home',
  data(){
    return {
      codes_show:false,
      // timeLimitColumns:['永久','7天','月卡','季度卡','年卡'],
      timeLimitColumns:[{text:'永久',v:0},'7天','月卡','季度卡','年卡'],
      config:{
        platform:'win32',
        num:1,
        dev_num:3,
        time_limit:0,
      },
      codes_list:[],
    }
  },
  components: {

  },
  methods:{
    onClickLeft(){
      this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    onConfirmTimeLimitPicker(value, index){
      this.showTimeLimitPicker = false;
      this.config.time_limit = value
      console.log(value,index)
    },
    copyCode(index,code){
      execCopy(`input_${index}`,code)
    },
    copyCodeAll(){
      let codes = '';
      this.codes_list.forEach(item => {
        codes+= item.code+'\n'
      });
      execCopy(`input_all`,codes)
      
    },
    onSubmit(values) {
      Toast('提示内容');
      // values['token'] = 'lizheng666'
      api.active_code.getAcCodes(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            this.codes_list = r.data || []
            this.codes_show = true
            setTimeout(()=>{
              Toast.success({position:'top', message:'获取成功'});
            })
          }else{
              console.log(res)
              Toast.fail('获取失败'+r.msg);
          }
      }).catch(err=>{
        Toast.fail('获取失败'+err);
      })
    },
    createCode() {
      Toast('创建激活码');
      let values = this.config
      // values['token'] = 'lizheng666'
      api.active_code.createAcCodes(values).then(res=>{
          let r = res.data;
          if(r.code == 1){
            console.log(res)
            this.codes_list = r.data || []
            this.codes_show = true
            setTimeout(()=>{
              Toast.success({message:'创建成功', position:'top'});
            })
          }else{
              console.log(res)
              Toast.fail('创建失败'+r.msg);
          }
      }).catch(err=>{
        Toast.fail('创建失败');
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.home-con{

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
  .van-radio{
    margin-bottom:5px
  }
  .copy-input{
    width: 1px;
    resize: none;
    border: none;
    outline: none;
    user-select: none;
    color: transparent;
    background: transparent;
    display: inline-block;
  }
}
</style>